import React, {Component} from "react";
import "./PopoutMenu.scss";
import { Link } from "react-router-dom";
import Backdrop from './Backdrop/Backdrop';
import '../Header/Header.scss';
import IndustriesDropdown from "../IndustriesDropdown/IndustriesDropdown";
import AdminDropdown from "../AdminDropdown/AdminDropdown";

const rootClass = "popout";


class PopoutMenu extends Component {

  render() {
    let attachedClasses  = [rootClass, 'close'];
    if (this.props.open) {
        attachedClasses = [rootClass, 'open'];
    }
    return (
      <div>
      <div className={attachedClasses.join(' ')}>
        <ul className="popout-list">
        <li>
            <Link className="menu-home" to="/" onClick={this.props.backdropClick}>
              Home
            </Link>
          </li>
          <li><IndustriesDropdown className="menu-industries" backdropClick={this.props.backdropClick} industries={this.props.industries} /></li>
          <li><Link className="menu-search" to="/search" onClick={this.props.backdropClick}>Search</Link></li>
          {
            this.props.user && this.props.admin.filter(user=> user === this.props.user.email).length ?
            <li><AdminDropdown className="menu-admin" backdropClick={this.props.backdropClick} admin={this.props.admin} /></li> : null
            // <li><Link to="/admin" onClick={this.props.backdropClick}>Admin</Link></li> : null
          }
          {
            this.props.user ?
            <li><button onClick={this.props.logout}>Logout</button></li> : null
          }
        </ul>
          
      </div>   
      <Backdrop show={this.props.open} backdropClick={this.props.backdropClick}></Backdrop>  
      </div>

  );
}

} 

export default PopoutMenu;