import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link
} from "@material-ui/core";

const ClientDataForm = ({open, onClose}) => {
    return (
        <> 
        <Dialog open={open} onClose={onClose}>
        <DialogTitle>CLIENT PROJECT SETUP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are required to complete OpenCloud scope form for client data. Download attached form here below, <strong>complete the first 3 tabs</strong> in the speadsheet and send to CMS OpenCloud Team <a href={`mailto:Cacmssales@delotte.ca`}>(Cacmssales@delotte.ca )</a>. Contact them if you have any questions related to filling the form
          </DialogContentText>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
                window.location.href='https://storage.cloud.google.com/partner-catalog-1.appspot.com/projectSetupForms/CMS_OpenCloud_Scope%20Form_Client_CSP_v0.4.xlsx'
            }}
            >
            Download CMS OpenCloud Form
        </Link>
        </DialogContent>

        </Dialog>
        </>
    )
} 

export default ClientDataForm;