import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div className="fixed-footer">
        <div className="ml-3">Copyright &copy; 2021 Deloitte Digital</div>        
    </div>
  );
}

export default Footer;