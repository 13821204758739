import React from "react";
import Card from "../../Card/Card";
import { Link } from "react-router-dom";
import "./Home.scss";


function Home(props) {
  
  //console.log("Home", props.industries )
  
  return (

    <div className="home-wrapper row m-0">
      <div className="splash-div col-12 m-0 p-0">
        <div className="col-xl-6 col-md-8">
          <h2>Google Cloud Platform Offerings</h2>
          <p>
            Having access to the best solutions and offerings possible will help you bring the best ideas to your clients (and potential clients!). This platform exists to make that easy.
            <br/><br/>
            Please take a moment to browse through the offerings, or try searching on specific priority workloads.
            <br/><br/>
            Please leave your comments, as your feedback is valuable. Our Product Owners review the comments and use them to help influence their product direction!
            <br/><br/>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSe6YpqPPbFX2Y-jLjk-dgeOwarIwFRWnU4g2prfzEtQZoIC3w/viewform">Survey available here</a>
          </p>


        </div>
        <img className="col-4 col-md-5 p-0 float-right" src="../Images/img-splash.png" alt="splash"></img>
      </div>
      <div className="cards-container col-12 row m-0">
        
        { props.data.length ?
          <div className="industry-container col-12 row p-0">
            <div className="industry-header col-12 m-0">
              <h5>Recently Added</h5>
            </div>
            { props.data
                .sort((a,b) => {
                  return new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime();
                }).reverse()
                .map((item, index) => 
                  index < 4 && //show only first 4 products
                  <Card key={index} data={item} analytics={props.analytics} />
                )
            }
          </div>
        : null }

        {/* Industry-wise products */}
        {
          props.data.length && props.industries.length ?

            props.industries.map((industry,i) => {
              return (
                <div key={i} className="industry-container col-12 row p-0">
                  <div className="industry-header col-12 m-0">
                    <h5>{industry}</h5>
                    <Link to={"/industry/" + (industry).toLowerCase().replace(/,/g,'').split(' ').join('-')}>
                      See All >
                    </Link>
                  </div>
                  {
                    props.data.filter(obj =>
                      obj.industry === industry
                    )
                    .sort((a,b) => {
                      return new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime();
                    }).reverse()
                    .map((item, index) => 
                      index < 4 && //show only first 4 products
                      <Card key={index} data={item} analytics={props.analytics} />
                    )
                  }
                </div>
              )
            })
            
          : <p>Loading products...</p>
        }
      </div>
    </div>
  );
}

export default Home;
