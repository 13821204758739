import React, { useState } from "react";
import { GarageContext } from "../contexts";

export const GarageStore = ({children}) => {
    const [garageOnboarding, setGarageOnboarding] = useState({
        dataClass: "Internal",
        customized_pro_size: "",
        proj_spec: {
          projName: "",
          wbs: "",
          project_tenure: "",
          monthly_consumption: "",
          parent_proj: "",
          Data_type: "Deloitte",
          Data_classification: "Public",
          proj_desc: "",
        },
        extensive_toolkit: {
          toolType: "",
          otherToolName: "",
          extToolKProjName: "",
          repo: "",
          adminAccess: false,
          toolkitUsers: [{"toolKitUserEmail": "", "toolKitUserName": ""}],
          gcp_service: [],
        },
    
        contactsUsersAccess: {
          primaryContactEmail: "",
          primaryContactName: "",
          SecContactEmail: "",
          SecContactName: "",
          budgetManagerEmail: "",
          budgetManagerName: "",
          businessOwnerEmail: "",
          businessOwnerName: "",
          whoNeedsAccess: "",
          otherUsers: [{"otherUserEmail": "", "otherUserName": ""}],
        },
      });

    return (
    <GarageContext.Provider value={{
        garageOnboarding,
        setGarageOnboarding,
    }}>
        {children}
    </GarageContext.Provider>
    )
    
}
export default GarageStore;