import React from "react";
import { useHistory } from 'react-router-dom'
import "./Card.scss";

function Card(props) {
  console.log(props)

  const bgblue = { background: '#00D5E6' };
  const bgyellow = { background: '#FDD300' };
  let history = useHistory();

  const bgCategory = props.data.type === 'Service Offering' ? bgblue
    : bgyellow;

  return props.data.industry === 'Google Garage' && props.data.doc_id === 'Ng7llQqwJLCq4krLfG7p' ? null : (

    <div className="card-wrapper col-lg-4 col-xl-3 col-md-6">
      <button
        onClick={
          () => {
            props.analytics.logEvent(('select_product'), {
              content_id: props.data.doc_id,
              search_value: props.searchState
            })
            history.push('/item/' + props.data.doc_id)
          }
        }
        className="card">
        <div className="card-content row m-0">
          <div className="col-12 p-0 row m-0">
            <img alt="" src={`../Images/icons/${props.data.icon}.png`} />
            <b>{props.data.title}</b>
          </div>
          <p className="col-12 p-0">
            {props.data.desc_short}
          </p>
          <span className="type-of-product" style={bgCategory}>
            {props.data.type}
          </span>
        </div>
      </button>
    </div>

  );

}

export default Card;