import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './AdminDropdown.scss'

class AdminDropdown extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  toggleMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    return (
      <div className="menu-admin" >
        <button onClick={this.toggleMenu}>Admin <i>&#9660;</i></button>
        {
          this.state.showMenu
            ? (
              <ul>
                  <li key="0"><Link onClick={this.props.backdropClick} to={"/admin/add"}>{"Add New Product"}</Link></li>
                  {/* <li key="1"><Link onClick={this.props.backdropClick} to={"/admin/edit"}>{"Edit"}</Link></li> */}
              </ul>
            ) : (null)
        }
      </div>
    );
  }
}

export default AdminDropdown;