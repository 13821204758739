import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './IndustriesDropdown.scss'

class IndustriesDropdown extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  toggleMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }
  
  render() {
    return (
      <div className="menu-industries" >
        <button onClick={this.toggleMenu}>Industries <i>&#9660;</i></button>
        {
          this.state.showMenu
            ? (
              <ul>
                {this.props.industries.map((item, index) => { return (<li key={index}><Link onClick={this.props.backdropClick} to={"/industry/" + (item).toLowerCase().replace(/,/g, '').split(' ').join('-')}>{item}</Link></li>); })}
              </ul>
            ) : (null)
        }
      </div>
    );
  }
}

export default IndustriesDropdown;