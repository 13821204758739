import React from "react"
import {Component} from "react";
import { Link, withRouter } from "react-router-dom";
import googleAuthentication from '../utils/firebase';
import "./Header.scss";
import PopoutMenu from "../PopoutMenu/PopoutMenu";
import IndustriesDropdown from '../IndustriesDropdown/IndustriesDropdown';
import AdminDropdown from '../AdminDropdown/AdminDropdown';

const rootClass = 'header';
const burgerClass = 'burger'


// This is the code for the banner at the top of the page
class Header extends Component {
  state = {
    showPopoutMenu: false
  }

  signInDiv = () => {
    return this.props.user && this.props.user.displayName
      ? <div>
          <img className={`${rootClass}__profile-image`} src={this.props.user.photoURL} alt="Display" />
          <div className={`${rootClass}__user-info`}>
            <span>{this.props.user.displayName}</span>
            <span>
              <button onClick={this.onLogoutClick}>Logout</button>
            </span>
          </div>
        </div>
      : <button onClick={this.onLoginClick}>Sign In</button>;
  }

  onLoginClick = async () => {
    try {
      const userRes = await googleAuthentication();
      this.props.setUser(userRes);
    } catch (e) {
      // Error handle
    }
  }

  onLogoutClick = () => {
    window.sessionStorage.removeItem(this.props.session);
    this.props.setUser({});
    if (this.props.location.pathname === "/admin") {
      this.props.history.push("/");
    }
    this.props.resetState()
  }

  togglePopout = () => {
    this.setState((prevState)=> {
      return {showPopoutMenu: !prevState.showPopoutMenu}
    })
  }

  onLogoutDrawer = () => {
    this.onLogoutClick();
    this.togglePopout();
  }

  render () {
    return (
      <div>
      <div className="mobileHeader">
        <Link to="/"><img className="logo-mobile" alt="logo" src="../Images/logo.png" /></Link>
        <div className={burgerClass} onClick={this.togglePopout} >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`signIn`}>
            {
              this.props.user && this.props.user.displayName
              ? <div>
                  <img className={`${rootClass}__profile-image`} src={this.props.user.photoURL} alt="Display" />
                  <div className={`${rootClass}__user-info`}>
                    <span>{this.props.user.displayName}</span>
                    <span>
                      <button onClick={this.onLogoutClick}>Logout</button>
                    </span>
                  </div>
                </div>
              : <button onClick={this.onLoginClick}>Sign In</button>
            }
        </div>
      </div>
      <PopoutMenu
        open={this.state.showPopoutMenu}
        backdropClick={this.togglePopout}
        industries={this.props.industries}
        user={this.props.user}
        admin={this.props.admin}
        logout={this.onLogoutDrawer}>
      </PopoutMenu>

      <div className={rootClass}>
        <div className={`${rootClass}__container`}>
        <Link to="/"><img className="logo" alt="logo" src="../Images/logo.png" /></Link>
        <Link className="menu-home" to="/">Home</Link>
          <IndustriesDropdown industries={this.props.industries} />
          <Link className="menu-search" to="/search">Search</Link>
          {
            this.props.user && this.props.admin.filter(user=> user === this.props.user.email).length ?
            <AdminDropdown admin={this.props.admin} /> : null
          }
          <div className={`${rootClass}__sign-in`}>
          {
            this.props.user && this.props.user.displayName
            ? <div>
                <img className={`${rootClass}__profile-image`} src={this.props.user.photoURL} alt="Display" />
                <div className={`${rootClass}__user-info`}>
                  <span>{this.props.user.displayName}</span>
                  <span>
                    <button onClick={this.onLogoutClick}>Logout</button>
                  </span>
                </div>
              </div>
            : <button onClick={this.onLoginClick}>Sign In</button>
          }
          </div>
        </div>
      </div>
      </div>
    );
  }
 
}

export default withRouter(Header);