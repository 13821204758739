import React, { useState } from "react";
import { useParams,Redirect,useHistory } from "react-router-dom";
import { createBrowserHistory } from 'history';
import "./CatalogItem.scss";
// import Consumption from "./Consumption";
import { firebaseApp, firebaseStorage } from '../../utils/firebase';


function CatalogItem(props) {
  let { doc_id } = useParams();
  const itemData = props.data.filter(item => String(item.doc_id) === doc_id)[0];
  const history = useHistory();
  //-----
  const [commentText, setCommentText] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [getNewFirebase, setGetNewFirebase] = useState(false);
  const [fileDesc, setFileDesc] = useState("");

  const [newFile, setNewFile] = useState({file: null, url:""});
  const [uploadProgress, setUploadProgress] = useState(0);

  const db = firebaseApp.firestore();
  
  React.useEffect(() => {
    const fetchCommentObj = async() => {
      const commentCollection = await db.collection("catalogItems").doc(doc_id).collection('comments').get();
      
      let unSortedCommentData = commentCollection.docs.map(obj => {
        return obj.data();
      });
      
      //-- sorting the comments & setting state
      setCommentData(unSortedCommentData.sort((a, b) => {
        var c = new Date(a.dateAdded);
        var d = new Date(b.dateAdded);
        return d-c; // reverse to change from desc to asc
      }));

    }
    fetchCommentObj();
    //--------------------
    const fetchMediaObj = async() => {
      const mediaCollection = await db.collection("catalogItems").doc(doc_id).collection('media').get();
      setMediaData(
        mediaCollection.docs.map(obj => {
          return obj.data();
        })
      );
    }
    fetchMediaObj();

  },[db,doc_id, getNewFirebase]);
  //----
  
  const bgblue = {background: '#00D5E6'};
  const bgyellow = {background: '#FDD300'};
  const bgCategory = itemData && itemData.type === 'Service Offering' ? bgblue 
                      : bgyellow;

  //console.log("filtered", itemData);
  //console.log(mediaData);

  const formatDate = (dateString) => {
    dateString = new Date (dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit"
    }).format(dateString);
  }

  const postComment = () => {
    const timestamp = new Date();
    let commentObj = {};
    commentObj.dateAdded = timestamp.toString();
    commentObj.name = props.user.displayName;
    commentObj.imgUrl = props.user.photoURL;
    commentObj.text = commentText;

    const firebaseCollectionComments = db.collection("catalogItems")
                                      .doc(doc_id).collection('comments');
    
    if (commentObj.text !== "") {
      firebaseCollectionComments.add(commentObj);
      setCommentText("");
      // --to make page refesh on adding comment
      setGetNewFirebase(!getNewFirebase);
    }

  }

  //--file upload ------------------
  const handleFileChange = ((e) => {
    if(e.target.files[0]) {
      setNewFile({
        "file": e.target.files[0],
        "url": e.target.value
      });
    }
    //console.log("FILE", e.target.files[0]);
  });

  const uploadFile = ((e) => {
    const storageRef = firebaseStorage.ref();
    const firebaseCollectionMedia = db.collection("catalogItems")
                                    .doc(doc_id).collection('media');
    
    if (newFile.file) {
      const mainFile = storageRef.child(`${doc_id}/${newFile.file.name}`);

      let fileObj = {};
      fileObj.desc = fileDesc;
      fileObj.type = newFile.file.type;
      fileObj.fileName = newFile.file.name;
      fileObj.uploadedBy = props.user.email;

      const uploadTask = mainFile.put(newFile.file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes)*100);
          setUploadProgress(progress);
        },
        
        (error) => {
          console.log("Upload failed", error);
        },

        () => { //success function
          mainFile.getDownloadURL().then((url) => {
            //console.log("URL",url);
            fileObj.firURL = url;
            //console.log(fileObj);
            firebaseCollectionMedia.add(fileObj);

            setGetNewFirebase(!getNewFirebase);
          });
          //-- set file to empty when upload complete
          setNewFile({file: null, url:""});
          setFileDesc("");
          setUploadProgress(0);
        }
      );

    } // if ends
    else {
      alert("Plese select a file!");
    }
  });

  //---file upload ends

  const browserBack = (e) => {
    const history = createBrowserHistory();
    history.goBack();
    e.preventDefault();
  }

  //---edit catalog info
  function editInfo (id) {
    history.push("/admin/edit/" + id);
  };
  //---edit catalog info ends

  return (
    !itemData ? <Redirect to="/404" /> 
    :
    <section className="catalog-item-container col-12 col-lg-6">
      
      {/* -----Catalog Item Info----- */}
      { itemData &&
      <div className="catalog-info-wrapper">
        <div>
          <button onClick={browserBack} className="link-back">&#8592;</button>
        </div>
        <img className="icon" alt="" src={`../Images/icons/${itemData.icon}.png`} />
        <h2>
            {itemData.title + "\t"}
            {
              props.user && props.admin && props.admin.includes(props.user.email) ?
                <button onClick={() => editInfo(itemData.doc_id)} className="btn-upload">Edit</button> : null
            }
        </h2>
        <p className="item-desc col-12 p-0">{itemData.desc_full}</p>
        <div>
          <b>Contact Name:&nbsp;</b>{itemData.contact_name}
        </div>
        <div>
          <b>Contact Email:&nbsp;</b><a href={`mailto:${itemData.contact}?cc=noreid@deloitte.ca; khalhasumi@google.com`}>{itemData.contact}</a>
        </div>
                 
        {/* Info Row 1 */}
        <div className="row m-0">
          <div className="values p-0 col-md-6">
            <b className="col-12 p-0">Business Value Statements</b>
            <ul>
              {
                itemData && itemData.values ? itemData.values.map((value,i) => 
                <li key={i}>{value}</li>)
                : null
              }
            </ul>
          </div>
          <div className="cases col-md-5 p-0 offset-md-1">
            {/* <Consumption data={itemData.consumption} />  */}
            <b className="col-12 p-0">References</b>
            <ul>
              {
                itemData && itemData.cases ? itemData.cases.map((caseItem,i) => 
                <li key={i}>{caseItem}</li>)
                : null
              }
            </ul>
          </div>
        </div>
        {/* Info Row 2 */}
        <div className="row ml-0 mr-0 mt-3">
          <div className="p-0 col-md-6">
            <b className="col-12 p-0">Industry</b>
            <p className="mt-1">{itemData.industry}</p>
          </div>
          <div className="col-md-5 p-0 offset-md-1">
            <div className="type-of-product row m-0">
              <b className="col-12 p-0">Type</b>
              <span style={bgCategory}>{itemData.type}</span>
            </div>
          </div>
        </div>
        {/* Info Row 3 */}
        <div className="row ml-0 mr-0 mt-3">
          <div className="p-0 col-md-6">
            <b className="col-12 p-0">Key Technologies</b>
            <p className="mt-1">{itemData.keyTechnology}</p>
          </div>

          <div className="priority-workloads col-md-5 p-0 offset-md-1">
            <b className="col-12 p-0">Priority Workloads</b>
            { itemData && itemData.priorityWorkloads.length ?
              <ul>
              {
                itemData.priorityWorkloads.map((caseItem,i) => 
                  <li key={i}>{caseItem}</li> )
              }
            </ul>
            :<p className="mt-1">No priority workload selected</p>
            }
          </div>
        </div>
      </div>
      }
            
      {/* -----File Upload----- */}
      <div className="media-wrapper">
        <h5 className="col-12 p-0">Materials</h5>
        <div className="media-list">
          { mediaData.length ?
            mediaData.map((item, i) => 
              <div className="media-item-wrap" key={i}>
                <a target="_blank" rel="noopener noreferrer" href={item.firURL} download>
                  <div className="media-item">
                    <img alt="icon" src={
                      item.type.indexOf("pdf") >= 0 ? "../Images/filetype/pdf.png" :
                      item.type.indexOf("presentation") >= 0 ? "../Images/filetype/powerpoint.png" :
                      item.type.indexOf("spreadsheet") >= 0 ? "../Images/filetype/excel.png" :
                      item.type.indexOf("wordprocessing") >= 0 ? "../Images/filetype/word.png" :
                      item.type.indexOf("image") >= 0 ? "../Images/filetype/image.png" :
                      item.type.indexOf("video") >= 0 ? "../Images/filetype/video.png" :
                      item.type.indexOf("audio") >= 0 ? "../Images/filetype/audio.png" :
                      "../Images/filetype/file.png"
                    } />
                  </div>
                  <p>{item.desc ? item.desc : item.fileName}</p>
                </a>
                {/* <p>{item.type}</p> */}
              </div>
            ) : <p>No material available at this time</p>
          }
        </div>
      </div>
      {
        props.user && props.admin && props.admin.includes(props.user.email) ?
        <div className="file-upload-wrapper">
          <hr/>
          <h5 className="col-12 p-0">Upload Material</h5>
          <div className="upload-section row">
            <div className="file-wrap col-md-6">
              <input value={newFile.url} onChange={e => handleFileChange(e)} type="file"></input>
              <progress value={uploadProgress} max="100" />
            </div>
            <div className="col-md-6 desc-wrap">
              <label className="m-0">File description (Max <b>15 char</b>)</label>&nbsp;
              <input maxLength="15" value={fileDesc} onChange={e => setFileDesc(e.target.value)} type="text" />
            </div>
          </div>
          <button onClick={uploadFile} className="btn-upload">Upload</button>
          <br/>
        </div>
        : null
      }
       
      {/* -----Comments section----- */}
      <div className="comments-container">
      { props.user ?
        <div className="enter-comment row m-0">
          <h5 className="col-12 p-0">Leave feedback/use cases</h5>
          <br/><br/>
          <img className="profile-image" src={props.user.photoURL} alt="Display" />
          <textarea rows="4" className="col" value={commentText} onChange={e => setCommentText(e.target.value)}></textarea>
          <div className="col-12 p-0">
            <button onClick={postComment} className="float-right">Submit</button>
          </div>
        </div>
      :
        <h5 className="mt-4 mb-4">Please sign in to add feedback/use cases  </h5> }
        <h5 className="col-12 p-0">Comments</h5>
        <div className="comments-list row m-0">
          {
            commentData.length ? commentData.map((comment,i) => 
              <div key={i} className="box-comment row">
                <img className="profile-image"
                  src={comment.imgUrl ? comment.imgUrl : "../Images/icon-user.png"} alt="Display" />
                <div className="col">
                  <b>{comment.name}</b>
                  <i>{formatDate(comment.dateAdded)}</i>
                  <p>{comment.text}</p>
                </div>
              </div> )
              : <p>No comments so far</p>
          }
        </div>
      </div>
      
    </section>

  );
}

export default CatalogItem;