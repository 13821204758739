import React, { useState } from 'react';
import Card from "../../Card/Card";
import '../Home/Home.scss';
import './Search.scss';

function Search(props) {
  const [searchResults, setSearchResults] = useState("");
  const [resultsNumber, setResultsNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  let noResultsFound = true;


  function searchLength(value) {
    return value.length >=2;
  }

  function searchIncludes(item, value) {
    let title = item.title.toLowerCase();
    let pwItems = JSON.stringify(item.priorityWorkloads.map((i)=> i.toLowerCase()));
    let pwPillars = JSON.stringify(item.priorityPillars.map((i)=> i.toLowerCase()));
    return (title.includes(value) || pwItems.includes(value) || pwPillars.includes(value));
  }



  const handleSearchChange = e => {
    let value = e.target.value
    setSearchTerm(value);
    let resultsFound = 0;
    props.data.map((item) => {
                if (searchLength(value)) {
                  if (searchIncludes(item, value))
                  {
                    resultsFound++;
                  }
                }
                return null;
              });
    setResultsNumber(resultsFound);
  }

  const returnResults = (event) => {
    event.preventDefault();
    setSearchResults(searchTerm.toLowerCase());
    if (searchTerm.trim().length >= 2) {
      props.analytics.logEvent('search', {
        search_value: searchTerm,
        results: resultsNumber
      });
    }
    
  };

  const resetSearch = () => {
    setSearchResults("");
    setSearchTerm("");
  }

  return (
    <div className="home-wrapper search-page row m-0">
      <form className="search-text-wrapper col-12">
        <div className="col-10 col-xl-5 col-md-6 p-0 row m-auto">
        <div className="input-holder col-10 p-0">
          <input className="col-12" placeholder="Search..." type="text"
            value={searchTerm} onChange={handleSearchChange} />
          <input type="button" value="x" onClick={resetSearch} />
        </div>
        <button className="col-2" onClick={returnResults} type="submit">Go</button>
        </div>
      </form>
      <div className="cards-container search col-12 row m-0">
        {
          props.data.length ?
            props.data
              .sort((a,b) => {
                return new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime();
              }).reverse()
              .map((item, index) => {
                if (searchLength({ searchResults }.searchResults)) {
                  if (searchIncludes(item, { searchResults }.searchResults))
                  {
                    noResultsFound = false;
                    return (
                      <Card analytics={props.analytics} searchState={searchResults} key={index} data={item} />
                    );
                  }
                } else {
                  noResultsFound = false;
                  return (
                    <Card  analytics={props.analytics} key={index} data={item} />
                  );
                }
                return null;
              })
            : <p>Loading products...</p>
        }

        { props.data.length && noResultsFound ?
          <p className="no-result">No Products Found</p>
        : null }
      </div>
    </div>

  );
}

export default Search;