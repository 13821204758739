import React from "react";
import "./Missing.scss";


function Missing(props) {

  //console.log("Home", props.data)

  return (
    <section className="section-404">
      {props.data.length !== 0 ?
        <div className="wrapper-404">
          <span aria-label="404 Image" role="img">&#128533;</span>
          <br />
          <h1>Uh-oh</h1>
          <h3>Page not found</h3>
          <br />
          <h5>The page you're looking for does not exist or an error has occured.</h5>
          <h5>Please head over to <a href="/">home</a> and try again.</h5>
        </div> : <p className="pl-4"><br/>Loading...</p>
      }
    </section>
  );
}

export default Missing;
