import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { createBrowserHistory } from "history";
import "./GoogleGarage.scss";
import { firebaseApp } from "../../utils/firebase";
import { GarageContext } from "../../contexts";
import ClientDataForm from "../MasterCloud/ClientDataForm";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

function GoogleGarageOnboard(props) {
  const infrastructureCollection = firebaseApp
    .firestore()
    .collection("infrastructure");

  const [clientData, setclientData] = useState(false);

  const [toolkitEmailError, setToolkitEmailError] = useState([false]);
  const [otherUserEmailError, setOtherUserEmailError] = useState([false]);
  const project_tenure = ["1", "2", "3", "4", "5", "6", ">6"]
  const monthly_consumption = ["100", "200", "250", "More than 250"]

  const EMAIL_REG =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { garageOnboarding, setGarageOnboarding } = useContext(GarageContext);

  const browserBack = (e) => {
    const history = createBrowserHistory();
    history.goBack();
    e.preventDefault();
  };

  const data_classification_options = [
    {
      value: "Public",
      label: "Public",
    },
    {
      value: "Confidential",
      label: "Confidential",
    },
    {
      value: "High Risk Confidential",
      label: "High Risk Confidential",
    },
  ];

  // Sections will now open or close based on their current state. Code has been
  //consolidated to handle close and open for all sections. The previous safe buttons
  //have been removed because they were redundant after using context to save input
  const ShowSection = (e) => {
    const section = e.target.id;
    const section_to_display =
      section === "section-1-button"
        ? "part-1-question"
        : section === "section-2-button"
        ? "part-2-question"
        : section === "section-3-button"
        ? "part-3-question"
        : "part-4-question";
    const displayState =
      document.getElementById(section_to_display).style.display;
    displayState === "block"
      ? (document.getElementById(section_to_display).style.display = "none")
      : (document.getElementById(section_to_display).style.display = "block");
  };

  const handleAddUserToolKitAccess = () => {
    //add usertoolkitaccess record
    const newExtToolKitUsers = [
      ...garageOnboarding.extensive_toolkit.toolkitUsers,
      { toolKitUserEmail: "", toolKitUserName: "" },
    ];
    const newExtToolKit = {
      ...garageOnboarding.extensive_toolkit,
      toolkitUsers: newExtToolKitUsers,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      extensive_toolkit: newExtToolKit,
    });

    //add toolkituseraccess record error
    const email_error_records = [...toolkitEmailError, false];
    setToolkitEmailError(email_error_records);
  };

  const handleAddUserSolutionAccess = () => {
    //add otherUserSolution Access record
    const newOtherUsers = [
      ...garageOnboarding.contactsUsersAccess.otherUsers,
      { otherUserEmail: "", otherUserName: "" },
    ];

    const newcontactsUsersAccess = {
      ...garageOnboarding.contactsUsersAccess,
      otherUsers: newOtherUsers,
    };

    setGarageOnboarding({
      ...garageOnboarding,
      contactsUsersAccess: newcontactsUsersAccess,
    });

    //add otherUsersEmailError record
    const otherUserEmailErrorRecords = [...otherUserEmailError, false];
    setOtherUserEmailError(otherUserEmailErrorRecords);
  };

  const handleRemoveUserToolKitAccess = (index) => {
    //remove toolkituseraccess record
    const records = [...garageOnboarding.extensive_toolkit.toolkitUsers];
    records.splice(index, 1);
    const newExtToolKit = {
      ...garageOnboarding.extensive_toolkit,
      toolkitUsers: records,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      extensive_toolkit: newExtToolKit,
    });

    //remove toolkituseraccess record error
    const email_error_records = [...toolkitEmailError];
    email_error_records.splice(index, 1);
    setToolkitEmailError(email_error_records);
  };

  const handleRemoveUserSolutionAccess = (index) => {
    //remove otherUsers record
    const records = [...garageOnboarding.contactsUsersAccess.otherUsers];
    records.splice(index, 1);
    const newcontactsUsersAccess = {
      ...garageOnboarding.contactsUsersAccess,
      otherUsers: records,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      contactsUsersAccess: newcontactsUsersAccess,
    });

    //remove otherUsersEmailError record
    const otherUserEmailErrorRecords = [...otherUserEmailError];
    otherUserEmailErrorRecords.splice(index, 1);
    setOtherUserEmailError(otherUserEmailErrorRecords);
  };

  const handleAddToolKitUserDetails = (e, index) => {
    const { name, value } = e.target;

    const newExtToolKitUsers = [
      ...garageOnboarding.extensive_toolkit.toolkitUsers,
    ];
    newExtToolKitUsers[index][name] = value;

    const newExtToolKit = {
      ...garageOnboarding.extensive_toolkit,
      toolkitUsers: newExtToolKitUsers,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      extensive_toolkit: newExtToolKit,
    });
  };

  const handleAddOtherUserDetails = (e, index) => {
    const { name, value } = e.target;

    const newOtherUsers = [...garageOnboarding.contactsUsersAccess.otherUsers];
    newOtherUsers[index][name] = value;

    const newContactsUsersAccess = {
      ...garageOnboarding.contactsUsersAccess,
      otherUsers: newOtherUsers,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      contactsUsersAccess: newContactsUsersAccess,
    });
  };

  // In Section 1 question, if user choose "Custom", show the text field
  const showCustomText = (e) => {
    var choice = document.getElementsByName("radio-buttons-group");
    if (choice[0].checked === true) {
      setclientData(true);
    }
  };

  // In Section 3 question, if user does NOT choose "N/A", show the hided questions of extensive toolkit
  const showPart3Hided = (e) => {
    var choice = document.getElementsByName("toolType");
    if (choice[2].checked !== true) {
      document.getElementById("part3-hided").style.display = "block";
    } else {
      document.getElementById("part3-hided").style.display = "none";
    }
  };

  // In the last Section 4 question, if user choose "Other", show the hided text fields of Email and Name
  const showPart4Hided = (e) => {
    var choice = document.getElementsByName("whoNeedsAccess");
    console.log(choice[1].checked);
    if (choice[1].checked) {
      document.getElementById("part4-hided").style.display = "block";
    } else {
      console.log("I came here");
      document.getElementById("part4-hided").style.display = "none";
    }
    console.log(document.getElementById("part4-hided").style.display);
  };

  const getDataClass = (e) => {
    setGarageOnboarding({
      ...garageOnboarding,
      dataClass: e.target.value,
    });
  };

  const handlePOCDuration= (duration) => {
    const newProjSpec = {
      ...garageOnboarding.proj_spec,
      project_tenure: duration,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      proj_spec: newProjSpec ,
    });
  };


  const handleMonthlyConsumption= (amount) => {
    const newProjSpec = {
      ...garageOnboarding.proj_spec,
      monthly_consumption: amount,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      proj_spec: newProjSpec ,
    });
  };


  const handleProjSpec = (e) => {
    const fieldName = e.target.name;
      console.log(fieldName)
      const newProjSpec = {
        ...garageOnboarding.proj_spec,
        [fieldName]: e.target.value,
      };
      setGarageOnboarding({ ...garageOnboarding, proj_spec: newProjSpec });
  };

  const handleExtToolKit = (e) => {
    const excludedFields = [
      "gcp_service",
      "toolKitUserEmail",
      "toolKitUserName",
    ];
    const fieldName = e.target.name;
    if (!excludedFields.includes(fieldName)) {
      const newExtToolKit = {
        ...garageOnboarding.extensive_toolkit,
        [fieldName]: e.target.value,
      };
      setGarageOnboarding({
        ...garageOnboarding,
        extensive_toolkit: newExtToolKit,
      });
    }
  };

  const handleGCPService = (new_services) => {
    const newExtToolKit = {
      ...garageOnboarding.extensive_toolkit,
      gcp_service: new_services,
    };
    setGarageOnboarding({
      ...garageOnboarding,
      extensive_toolkit: newExtToolKit,
    });
  };

  const handleContactsUserAcc = (e) => {
    const excludedFields = ["otherUserEmail", "otherUserName"];
    const fieldName = e.target.name;
    if (!excludedFields.includes(fieldName)) {
      const newContactUsersAccess = {
        ...garageOnboarding.contactsUsersAccess,
        [fieldName]: e.target.value,
      };
      setGarageOnboarding({
        ...garageOnboarding,
        contactsUsersAccess: newContactUsersAccess,
      });
    }
  };

  const handleSubmission = () => {
    infrastructureCollection
      .add({ ...garageOnboarding, time_submitted: new Date() })
      .then((doc) => {
        resetUserInputs();
        alert(`Submission created with the ID: ${doc.id}`);
      })
      .catch((error) => {
        console.log(`problem saving with ${error}`);
      });
  };

  const onDialogClose = () => {
    setclientData(false);
  };
  const resetUserInputs = () => {
    setGarageOnboarding({
      dataClass: "Internal",
      project_size: "",
      proj_spec: {
        projName: "",
        wbs: "",
        parent_proj: "",
        Data_type: "Deloitte",
        Data_classification: "Public",
        project_tenure: "",
        monthly_consumption: "",
        proj_desc: "",
      },
      extensive_toolkit: {
        toolType: "",
        otherToolName: "",
        extToolKProjName: "",
        repo: "",
        toolkitUsers: [{ toolKitUserEmail: "", toolKitUserName: "" }],
        adminAccess: false,
        gcp_service: [],
      },
      contactsUsersAccess: {
        primaryContactEmail: "",
        primaryContactName: "",
        SecContactEmail: "",
        SecContactName: "",
        budgetManagerEmail: "",
        budgetManagerName: "",
        businessOwnerEmail: "",
        businessOwnerName: "",
        otherOwnerEmail: "",
        otherOwnerName: "",
        whoNeedsAccess: "",
        otherUsers: [{ otherUserEmail: "", otherUserName: "" }],
      },
    });
  };

  const toolkitUsersLocal = garageOnboarding.extensive_toolkit.toolkitUsers;
  const otherUsersLocal = garageOnboarding.contactsUsersAccess.otherUsers;

  return (
    <section className="gg-onboarding-container row">
      <div className="header-wrapper col-12">
        <p className="sub-header">Arrive At Where You Want In One Stop</p>
        <h1>Google Garage</h1>
        <br />
        <p className="item-desc col-6">
          We’re here to enable Deloitte teams bring their solutions to market
          using Google technology. Enjoy your project success with our one-stop
          portal, pre-configured monitoring & alerts, pre-packaged environments,
          and path to production. Begin your journey with us.
        </p>
        <img
          className="icon float-right"
          alt=""
          src={`../Images/icons/google garage icon.png`}
        />
        <div>
          <button className="btn-onboarding">
            <a
              className="btn-onboarding-text"
              target="_blank"
              rel="noopener noreferrer"
              href={"https://bit.ly/2Q8IrC0"}
              download
            >
              Learn More about Us
            </a>
          </button>
        </div>
      </div>

      <div className="form-instruction col-8">
        <p className="sub-header-3">
          {" "}
          Want to Explore More Services? Back to Our
          <button className="go-back" onClick={browserBack}>
            {" "}
            Google Garage Landing Page
          </button>
        </p>
        <b>
          {" "}
          Thanks for choosing Google Garage! Please answer the following
          questions to configure the project.{" "}
        </b>
        <br />
        <b> Reach out to Rajesh Chopra (</b>
        <a href={`mailto:rachopra@deloitte.ca`}>rachopra@deloitte.ca</a>
        <b>) if you have any question. </b>
        <br />
        <br />
        <p>
          {" "}
          Note: All questions marked with <b> * </b> are required.{" "}
        </p>
      </div>

      <div className="contact col-2">
        <b> Contacts </b>
        <table>
          <thead>
            <tr>
              <td>
                {" "}
                <img
                  className="profile"
                  alt=""
                  src={`../Images/icons/winston.jpg`}
                />{" "}
              </td>
              <td>
                <b className="contact-text">Winston Yong</b>
                <p className="contact-text">Google Garage Lead</p>
                <a className="contact-text" href={`mailto:wiyong@deloitte.ca`}>
                  wiyong@deloitte.ca
                </a>
              </td>
            </tr>
          </thead>
          <thead>
            <tr>
              <td>
                {" "}
                <img
                  className="profile"
                  alt=""
                  src={`../Images/icons/chandy.jpg`}
                />{" "}
              </td>
              <td>
                <b className="contact-text">Chandy Abraham</b>
                <p className="contact-text">Google Garage CIO/CTO</p>
                <a
                  className="contact-text"
                  href={`mailto:chaabraham@deloitte.ca`}
                >
                  chaabraham@deloitte.ca
                </a>
              </td>
            </tr>
          </thead>
          <thead>
            <tr>
              <td>
                {" "}
                <img
                  className="profile"
                  alt=""
                  src={`../Images/icons/rajesh.jpg`}
                />{" "}
              </td>
              <td>
                <b className="contact-text">Rajesh Chopra</b>
                <p className="contact-text">Google Garage COO</p>
                <a
                  className="contact-text"
                  href={`mailto:rachopra@deloitte.ca`}
                >
                  rachopra@deloitte.ca
                </a>
              </td>
            </tr>
          </thead>
        </table>
      </div>

      <div
        className="google-garage-onboarding-form col-8" /*onSubmit={handleSubmit(onSubmit)}*/
      >
        {/* 1 */}
        <div className="part1-title row">
          <div className="circle-1" id="section-1-button" onClick={ShowSection}>
            1
          </div>
          <p className="part1-title-text"> Data Classification </p>
        </div>
        <hr className="part1-line" />
        <div
          className="input-wrap"
          id="part-1-question"
          onChange={getDataClass}
        >
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Do you need to store Client data or Client IP
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="No"
              name="radio-buttons-group"
            >
              <FormControlLabel
                control={
                  <Radio
                    value="Client"
                    onClick={(e) => {
                      showCustomText(e);
                      setGarageOnboarding({
                        ...garageOnboarding,
                        dataClass: "Client",
                      });
                      setclientData(true);
                    }}
                    checked={
                      garageOnboarding.dataClass === "Client" ? true : false
                    }
                  />
                }
                label="Yes"
              />
              <ClientDataForm open={clientData} onClose={onDialogClose} />
              <FormControlLabel
                control={
                  <Radio
                    value="Internal"
                    onClick={(e) => showCustomText()}
                    checked={
                      garageOnboarding.dataClass === "Internal" ? true : false
                    }
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
          <p>
            {" "}
            Need more time to consider? Click{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://bit.ly/3aR24pk"}
              download
            >
              {" "}
              here{" "}
            </a>
            to access more details.
          </p>
        </div>
        {garageOnboarding.dataClass === "Client" ? null : (
          <>
            {/* 2 */}
            <div className="part2-title row">
              <div
                className="circle-2"
                id="section-2-button"
                onClick={ShowSection}
              >
                2
              </div>
              <p className="part2-title-text"> Other Project Specifications </p>
            </div>
            <hr className="part2-line" />
            <div
              className="input-wrap"
              id="part-2-question"
              style={{ display: "none" }}
            >
              <TextField
                className="text-input-material"
                required
                id="proj-name"
                name="projName"
                label="Name of the project?"
                value={garageOnboarding.proj_spec.projName}
                onChange={handleProjSpec}
              />
              <TextField
                className="text-input-material"
                required
                id="wbs-code"
                name="wbs"
                label="WBS code?"
                defaultValue="Hello World"
                value={garageOnboarding.proj_spec.wbs}
                onChange={handleProjSpec}
              />
              <TextField
                className="text-input-material"
                required
                id="parent-proj"
                name="parent_proj"
                label="Parent project in case you need a
                sub-Project (N/A if not applicable)"
                value={garageOnboarding.proj_spec.parent_proj}
                onChange={handleProjSpec}
              />
              <Autocomplete
                id="project-tenure"
                sx={{ width: 300 }}
                options={project_tenure}
                autoHighlight
                getOptionLabel={(option) => option}
                onChange={(event, value) => handlePOCDuration(value)}
                value = {garageOnboarding.proj_spec.project_tenure}
                renderInput={(params) => (
                  <TextField
                  name="project_tenure"
                    className="text-input-material"
                    {...params}
                    label="Expected POC duration on Garage (in months)"
                  />
                )}
              />
              <Autocomplete
                id="monthly-consumption"
                sx={{ width: 300 }}
                options={monthly_consumption}
                autoHighlight
                getOptionLabel={(option) => option}
                value ={garageOnboarding.proj_spec.monthly_consumption}
                onChange={(event, value) => handleMonthlyConsumption(value)}
                renderInput={(params) => (
                  <TextField
                  name="monthly_consumption"
                    className="text-input-material"
                    {...params}
                    label="Monthly Consumption ($)"
                  />
                )}
              />

              <div className="data-classification row">
                <TextField
                  className="text-input-material"
                  name="Data_classification"
                  id="data-classfication"
                  select
                  label="Provide Deloitte Data Classification"
                  value={garageOnboarding.proj_spec.Data_classification}
                  onChange={handleProjSpec}
                >
                  {data_classification_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <TextField
                className="text-input-material"
                required
                id="proj-desc"
                name="proj_desc"
                label="Please provide the project description"
                value={garageOnboarding.proj_spec.proj_desc}
                onChange={handleProjSpec}
              />
              <br />
            </div>

            {/* 3 */}
            <div className="part3-title row">
              <div
                className="circle-3"
                id="section-3-button"
                onClick={ShowSection}
              >
                3
              </div>
              <p className="part3-title-text"> Extensive Toolkit </p>
            </div>
            <hr className="part3-line" />
            <div
              className="input-wrap"
              id="part-3-question"
              style={{ display: "none" }}
              onChange={handleExtToolKit}
            >
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Do you need access to the following tools for your GCP
                  project?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="No"
                  name="toolType"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        value="Bit Bucket"
                        onClick={showPart3Hided}
                        checked={
                          garageOnboarding.extensive_toolkit.toolType ===
                          "Bit Bucket"
                            ? true
                            : false
                        }
                      />
                    }
                    label="Bit Bucket"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        value="Other"
                        onClick={showPart3Hided}
                        checked={
                          garageOnboarding.extensive_toolkit.toolType ===
                          "Other"
                            ? true
                            : false
                        }
                      />
                    }
                    label="Other"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        value="N/A"
                        onClick={showPart3Hided}
                        checked={
                          garageOnboarding.extensive_toolkit.toolType === "N/A"
                            ? true
                            : false
                        }
                      />
                    }
                    label="N/A"
                  />
                </RadioGroup>
              </FormControl>
              <div
                className="part3-hided-question"
                id="part3-hided"
                style={{ display: "none" }}
              >
                <p>
                  {" "}
                  What are the project name and repostory name of the tool?{" "}
                  <b> * </b>{" "}
                </p>
                <TextField
                  className="project-name-input"
                  required
                  name="extToolKProjName"
                  label="Project Name"
                  value={garageOnboarding.extensive_toolkit.extToolKProjName}
                />
                <TextField
                  className="repository-name-input"
                  required
                  name="repo"
                  label="Repository Name"
                  value={garageOnboarding.extensive_toolkit.repo}
                />

                <p>
                  {" "}
                  Who needs access to the tool? <b> * </b>{" "}
                </p>
                {toolkitUsersLocal.map((oneTool, index) => (
                  <>
                    <div key={index} className="row">
                      <TextField
                        className="part3-email-input"
                        required
                        name="toolKitUserEmail"
                        error={toolkitEmailError[index]}
                        defaultValue={
                          garageOnboarding.extensive_toolkit.toolKitEmail
                        }
                        helperText={
                          toolkitEmailError[index]
                            ? "Invalid email format"
                            : null
                        }
                        label="Email"
                        value={toolkitUsersLocal[index]["toolKitUserEmail"]}
                        onBlur={() => {
                          toolkitUsersLocal[index]["toolKitUserEmail"] &&
                            setToolkitEmailError((toolkitEmailError) => {
                              toolkitEmailError[index] = !!!EMAIL_REG.test(
                                toolkitUsersLocal[index][
                                  "toolKitUserEmail"
                                ].toLowerCase()
                              );

                              return [...toolkitEmailError];
                            });
                        }}
                        onChange={(e) => {
                          handleAddToolKitUserDetails(e, index);
                        }}
                        onFocus={() => {
                          toolkitEmailError[index] = false;
                          setToolkitEmailError((toolkitEmailError) => [
                            ...toolkitEmailError,
                          ]);
                        }}
                      />
                      <TextField
                        className="part3-name-input"
                        required
                        name="toolKitUserName"
                        label="Name"
                        value={toolkitUsersLocal[index]["toolKitUserName"]}
                        onChange={(e) => {
                          handleAddToolKitUserDetails(e, index);
                        }}
                      />
                      <div className="part3-admin-access">
                        <div>
                          {" "}
                          <input
                            type="radio"
                            name="adminAccess"
                            value="true"
                          />{" "}
                          Admin Access
                        </div>
                        {toolkitUsersLocal.length > 1 && (
                          <div>
                            <Button
                              variant="text"
                              onClick={() =>
                                handleRemoveUserToolKitAccess(index)
                              }
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    {toolkitUsersLocal.length - 1 === index &&
                      toolkitUsersLocal.length < 5 && (
                        <div className="add-container row">
                          <span
                            className="add-symbol"
                            onClick={handleAddUserToolKitAccess}
                          >
                            &#x2b;
                          </span>
                          <p className="add-text"> Click here to add more </p>
                        </div>
                      )}
                  </>
                ))}
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(event, value) => handleGCPService(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="What GCP service(s) do you need access to?"
                      className="text-input"
                      name="gcp_service"
                      value={garageOnboarding.extensive_toolkit.gcp_service}
                    />
                  )}
                />
              </div>
              <br />
            </div>

            {/* 4 */}
            <div className="part4-title row">
              <div
                className="circle-4"
                id="section-4-button"
                onClick={ShowSection}
              >
                4
              </div>
              <p className="part4-title-text"> Contacts & User Access </p>
            </div>
            <hr className="part4-line" />
            <div
              className="input-wrap"
              id="part-4-question"
              style={{ display: "none" }}
              onChange={handleContactsUserAcc}
            >
              <p>
                {" "}
                Who are the main contacts of this project? <b> * </b>{" "}
              </p>
              <div className="row">
                <TextField
                  className="user-info-name"
                  required
                  name="primaryContactEmail"
                  label="Primary Contact Email"
                  value={
                    garageOnboarding.contactsUsersAccess.primaryContactEmail
                  }
                />
                <TextField
                  className="user-info-email"
                  required
                  name="primaryContactName"
                  label="Primary Contact Name"
                  value={
                    garageOnboarding.contactsUsersAccess.primaryContactName
                  }
                />
              </div>
              <div className="row">
                <TextField
                  className="user-info-email"
                  required
                  name="SecContactEmail"
                  label="Secondary Contact Email"
                  value={garageOnboarding.contactsUsersAccess.SecContactEmail}
                />
                <TextField
                  className="user-info-name"
                  required
                  name="SecContactName"
                  label="Secondary Contact Name"
                  value={garageOnboarding.contactsUsersAccess.SecContactName}
                />
              </div>
              <div className="user-info row">
                <TextField
                  className="user-info-email"
                  required
                  name="budgetManagerEmail"
                  label="Budget Manager Email"
                  value={
                    garageOnboarding.contactsUsersAccess.budgetManagerEmail
                  }
                />
                <TextField
                  className="user-info-name"
                  required
                  name="budgetManagerName"
                  label="Budget Manager Name"
                  value={garageOnboarding.contactsUsersAccess.budgetManagerName}
                />
              </div>
              <div className="user-info row">
                <TextField
                  className="user-info-email"
                  required
                  name="businessOwnerEmail"
                  label="Business Owner (PPMD) Email"
                  value={
                    garageOnboarding.contactsUsersAccess.businessOwnerEmail
                  }
                />
                <TextField
                  className="user-info-name"
                  required
                  name="businessOwnerName"
                  label="Business Owner (PPMD) Name"
                  value={garageOnboarding.contactsUsersAccess.businessOwnerName}
                />
              </div>
              <div className="user-info row">
                <TextField
                  className="user-info-email"
                  required
                  name="otherOwnerEmail"
                  label="Other Admin/Owner Email"
                  value={garageOnboarding.contactsUsersAccess.otherOwnerEmail}
                />
                <TextField
                  className="user-info-name"
                  required
                  name="otherOwnerName"
                  label="Other Admin/Owner Name"
                  value={garageOnboarding.contactsUsersAccess.otherOwnerName}
                />
              </div>
              <p>
                {" "}
                Who needs access to the application / solution? <b> * </b>{" "}
              </p>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Do you need access to the following tools for your GCP
                  project?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="No"
                  name="whoNeedsAccess"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        value="All"
                        onClick={showPart4Hided}
                        checked={
                          garageOnboarding.contactsUsersAccess
                            .whoNeedsAccess === "All"
                            ? true
                            : false
                        }
                      />
                    }
                    label="All Above"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        value="Others"
                        onClick={showPart4Hided}
                        checked={
                          garageOnboarding.contactsUsersAccess
                            .whoNeedsAccess === "Others"
                            ? true
                            : false
                        }
                      />
                    }
                    label="Others"
                  />
                </RadioGroup>
              </FormControl>
              <div
                className="part4-hided-question"
                id="part4-hided"
                style={{ display: "none" }}
              >
                {otherUsersLocal.map((otherUser, index) => (
                  <>
                    <div key={index} className="row">
                      <TextField
                        className="part3-email-input"
                        required
                        name="otherUserEmail"
                        label="Email"
                        error={otherUserEmailError[index]}
                        value={otherUsersLocal[index]["otherUserEmail"]}
                        helperText={
                          otherUserEmailError[index]
                            ? "Invalid email format"
                            : null
                        }
                        onChange={(e) => {
                          handleAddOtherUserDetails(e, index);
                        }}
                        onBlur={() => {
                          otherUsersLocal[index]["otherUserEmail"] &&
                            setOtherUserEmailError((otherUserEmailError) => {
                              otherUserEmailError[index] = !!!EMAIL_REG.test(
                                otherUsersLocal[index][
                                  "otherUserEmail"
                                ].toLowerCase()
                              );

                              return [...otherUserEmailError];
                            });
                        }}
                        onFocus={() => {
                          otherUserEmailError[index] = false;
                          setOtherUserEmailError((otherUserEmailError) => [
                            ...otherUserEmailError,
                          ]);
                        }}
                      />
                      <TextField
                        className="part3-name-input"
                        required
                        name="otherUserName"
                        label="Name"
                        value={otherUsersLocal[index]["otherUserName"]}
                        onChange={(e) => {
                          handleAddOtherUserDetails(e, index);
                        }}
                      />
                      {otherUsersLocal.length > 1 && (
                        <div>
                          <Button
                            variant="text"
                            onClick={() =>
                              handleRemoveUserSolutionAccess(index)
                            }
                          >
                            Remove
                          </Button>
                        </div>
                      )}
                    </div>
                    {otherUsersLocal.length - 1 === index &&
                      otherUsersLocal.length < 5 && (
                        <div className="add-container row">
                          <span
                            className="add-symbol"
                            onClick={handleAddUserSolutionAccess}
                          >
                            &#x2b;
                          </span>
                          <p className="add-text"> Click here to add more </p>
                        </div>
                      )}
                  </>
                ))}

                <br />
              </div>
            </div>
            <br />
            <Button
              className="submitButton"
              variant="contained"
              onClick={handleSubmission}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </section>
  );
}

export default GoogleGarageOnboard;
