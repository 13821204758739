import React from "react";
import Card from "../../Card/Card";
import '../Home/Home.scss';

function Category(props) {

  //console.log("CAT", props.data,props.industry)
  
  return (
    
    <div className="home-wrapper">
    { props.data && props.industry ?
      <div className="row m-0">
      <div className="splash-div col-12 m-0 p-0">
        <div className="col-xl-6 col-md-8">
          <h2 className="m-0">{props.industry}</h2>
          {/* <p>All offerings related to {props.industry}</p> */}
        </div>
        <img className="col-4 col-md-5 p-0 float-right" src="../Images/img-splash.png" alt="splash"></img>
      </div>
      <div className="cards-container category col-12 row m-0">
        {props.data
          .sort((a,b) => {
            return new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime();
          }).reverse()
          .map((item, index) => 
            (item.industry === props.industry)
              ? <Card analytics={props.analytics} key={index} data={item} />
              : null
          )
        }
      </div></div>
    : null}
    { props.data && props.industry && props.industry !== "Foundational" ?
      <div className="row m-0">
      <div className="splash-div col-12 m-0 p-0">
        <div className="col-xl-6 col-md-8">
          <h2 className="m-0">Foundational</h2>
          {/* <p>All offerings related to {props.industry}</p> */}
        </div>
        <img className="col-4 col-md-5 p-0 float-right" src="../Images/img-splash.png" alt="splash"></img>
      </div>
      <div className="cards-container category col-12 row m-0">
        {props.data
          .sort((a,b) => {
            return new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime();
          }).reverse()
          .map((item, index) => 
            (  item.industry ==="Foundational" )
              ? <Card analytics={props.analytics} key={index} data={item} />
              : null
          )
        }
      </div></div>
    : null}
    </div>
    
  );

}

export default Category;