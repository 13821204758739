import React, { useState } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./CatalogItemGoogleG.scss";
// import Consumption from "./Consumption";
import { firebaseApp } from "../../utils/firebase";

function CatalogItemGoogleGarage(props) {
  const mainHistory = useHistory();
  let { doc_id } = useParams();
  const itemData = props.data.filter(
    (item) => String(item.doc_id) === doc_id
  )[0];
  //-----
  const [getNewFirebase] = useState(false);

  const db = firebaseApp.firestore();

  React.useEffect(() => {}, [db, doc_id, getNewFirebase]);
  //----

  //console.log("filtered", itemData);
  //console.log(mediaData);

  //---file upload ends

  const browserBack = (e) => {
    const history = createBrowserHistory();
    history.goBack();
    e.preventDefault();
  };

  return !itemData ? (
    <Redirect to="/404" />
  ) : (
    <section className=" google-garage-container  row">
      {itemData && (
        <div className="catalog-info-wrapper container">
          <div>
            <button onClick={browserBack} className="link-back">
              &#8592;
            </button>
          </div>
          <img
            className="icon"
            alt=""
            src={`../Images/icons/${itemData.icon}.png`}
          />
          <h2>{itemData.title + "\t"}</h2>
          <br />
          <p className="item-desc">
            We’re here to enable Deloitte teams bring their solutions to market
            using Google technology. Enjoy your project success with our
            one-stop portal, pre-configured monitoring & alerts, pre-packaged
            environments, and path to production. Begin your journey with us.
          </p>
          <div>
            <button className="btn-onboarding">
              <a
                className="btn-onboarding-text"
                target="_blank"
                rel="noopener noreferrer"
                href={"https://bit.ly/2Q8IrC0"}
                download
              >
                Learn More about Us
              </a>
            </button>
          </div>
        </div>
      )}

      {itemData && (
        <div className="catalog-service-wrapper">
          <div className="googleIcon">
            <img
              className="guiding-principle col-2"
              alt=""
              src={`../Images/icons/guiding principle.png`}
            />
            <img
              className="icon googleIcon"
              alt=""
              src={`../Images/icons/google garage icon.png`}
            />
          </div>
          <p className="sub-header-2">
            Start Turning Your Ideas into Solutions with Us
          </p>

          <div className="data-analytics col-8">
            <img
              className="icon"
              alt=""
              src={`../Images/icons/brainstorming.png`}
            />
            <h4>{itemData.title}</h4>
            <p>
              <b>
                Thank you for choosing Google Garage (GG) to host your
                accelerator.
              </b>
            </p>
            <ul>
              {" "}
              <li>
                Click "Set up your environment” button below and complete the
                form{" "}
              </li>
              <li>
                Send us an email when you’re done and we will take care of the
                rest!
              </li>
            </ul>

            <br />
            <button
              className="btn-setup-environment"
              onClick={() => mainHistory.push("/googlegarage/onboarding")}
            >
              Set up your environment
            </button>
            <br />
          </div>
          <div className="contact col-6">
            <b> Contacts </b>
            <table>
              <thead>
                <tr>
                  <td>
                    {" "}
                    <img
                      className="profile"
                      alt=""
                      src={`../Images/icons/winston.jpg`}
                    />{" "}
                  </td>
                  <td>
                    <b className="contact-text">Winston Yong</b>
                    <p className="contact-text">Google Garage Lead</p>
                    <a
                      className="contact-text"
                      href={`mailto:wiyong@deloitte.ca`}
                    >
                      wiyong@deloitte.ca
                    </a>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <td>
                    {" "}
                    <img
                      className="profile"
                      alt=""
                      src={`../Images/icons/chandy.jpg`}
                    />{" "}
                  </td>
                  <td>
                    <b className="contact-text">Chandy Abraham</b>
                    <p className="contact-text">Google Garage CIO/CTO</p>
                    <a
                      className="contact-text"
                      href={`mailto:chaabraham@deloitte.ca`}
                    >
                      chaabraham@deloitte.ca
                    </a>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <td>
                    {" "}
                    <img
                      className="profile"
                      alt=""
                      src={`../Images/icons/rajesh.jpg`}
                    />{" "}
                  </td>
                  <td>
                    <b className="contact-text">Rajesh Chopra</b>
                    <p className="contact-text">Google Garage COO</p>
                    <a
                      className="contact-text"
                      href={`mailto:rachopra@deloitte.ca`}
                    >
                      rachopra@deloitte.ca
                    </a>
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      )}

      <p className="sub-header-3">
        Want to Explore More Solutions? Back to Our
        <button className="go-back" onClick={browserBack}>
          GCP Offerings Page
        </button>
      </p>

      {itemData && (
        <div className="service-wrapper  row">
          <b className="service-title col-12"> Our Services </b>
          <div className="individual-service">
            <img
              className="service-image"
              alt=""
              src={`../Images/icons/one-stop-portal.svg`}
            />
            <br />
            <b className="individual-service-title"> One-stop Portal </b>
            <br />
            <p className="individual-service-desc">
              Our portal, gcpofferings.com, is a one-stop shop to access Google
              Garage Services, focused on user-experience (think Amazon
              or ServiceNow)
            </p>
          </div>
          <div className="individual-service">
            <img
              className="service-image"
              alt=""
              src={`../Images/icons/monitoring&alerts.svg`}
            />
            <br />
            <b className="individual-service-title"> Monitoring & Alerts </b>
            <br />
            <p className="individual-service-desc">
              Monitoring has been pre-configured in the environments, with
              alerts (cost, system performance, etc) to help you make your
              code efficient and cost-effective. We will also
              provide light-touch support as needed
            </p>
          </div>
          <div className="individual-service">
            <img
              className="service-image"
              alt=""
              src={`../Images/icons/pre-packaged-environments.svg`}
            />
            <br />
            <b className="individual-service-environment">
              {" "}
              Pre-packaged Environments{" "}
            </b>
            <br />
            <p className="individual-service-desc">
              Choose from a starting set of “t-shirt sized”  environments, which
              include Source Code Repository, that rapidly deploy to get you
              coding  quickly
            </p>
          </div>
          <div className="individual-service">
            <img
              className="service-image"
              alt=""
              src={`../Images/icons/path-to-production.svg`}
            />
            <br />
            <b className="individual-service-title"> Path to Production </b>
            <br />
            <p className="individual-service-desc">
              The Garage uses a deployment pipeline that will setup the right
              environments (e.g. Dev, Test, Pre-Prod) – and we will guide you in
              onboarding to one of our supported production environments 
              (MasterCloud, DMC)
            </p>
          </div>
        </div>
      )}
    </section>
  );
}

export default CatalogItemGoogleGarage;
