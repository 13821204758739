import React, {useState} from "react";
import { useParams,Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Admin.scss";
import { firebaseApp } from '../../utils/firebase';

function AdminEdit(props) {

  let { doc_id } = useParams();
  const itemData = props.data.filter(item => String(item.doc_id) === doc_id)[0];

  const db = firebaseApp.firestore();
  const {register, errors, handleSubmit, reset} = useForm();

  const [industryOptions, setIndustryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [showIconSprite, setShowIconSprite] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState("");
  const [togglePW, setTogglePW] = useState(false);

  const iconOptions = [
    "account", "ambulance",
    "barcode1", "barcode2",
    "battery", "bill",
    "brainstorming", "budgeting",
    "buildings", "business",
    "fuel", "hands",
    "plane", "solutions"
  ];

  React.useEffect(() => {
    const fetchPicklistItems = async() => {
      const industriesDB = await db.collection("industries").get();
      let industries = industriesDB.docs.map(obj => {
        return obj.data();
      });
      setIndustryOptions(industries.map(item => item.name));
      
      let categoriesDB = await db.collection("typeOfProduct").get();
      let categories = categoriesDB.docs.map(obj => {
        return obj.data();
      });
      setCategoryOptions(categories.map(item => item.name).sort());
    }
    fetchPicklistItems();
  },[db]);

  props.sortIndus(industryOptions);

  const showHidePW = (event) => {
    event.preventDefault();
    setTogglePW(!togglePW);
  }

  const getAllProducts = async() => {
    const productCollection = await db.collection("catalogItems").get();
    
    props.setData(
      productCollection.docs.map(obj => {
        let data = obj.data();
        data.doc_id = obj.id;
        return data;
      })
    );
  }

  const pwDataConvert = (data) => {
    const fullPillarList = (Object.entries(data));

    let pillarList = [];
    let verticalList = [];

    for (var pillar in fullPillarList) {
      let startArray = Object.entries(fullPillarList[pillar]);
      let currentPillar = startArray[0][1];
      let pillarAdded = false;
      let priorities = Object.keys(startArray[1][1]);
      let values = Object.values(startArray[1][1])
      for (var key in priorities) {
        if (values[key]) {
          verticalList.push(priorities[key]);
          if (!pillarAdded) {
            pillarList.push(currentPillar);
          }
          pillarAdded=true;
        }
      }
    }

    return [verticalList, pillarList];
  }

  const onSubmit = (data) => {
    let myData = data;
    myData.values = myData.values.split(/\r?\n/);
    if (myData.cases) {
      myData.cases = myData.cases.split(/\r?\n/);
    }
    // TODO: dateModified
    //myData.dateAdded = new Date().toString();
    // TODO: modifiedBy
    //myData.addedBy = props.user.email;

    const pwFormatted = pwDataConvert(myData.PW);
    myData.priorityWorkloads = pwFormatted[0];
    myData.priorityPillars = pwFormatted[1];

    const firebaseDocument = db.doc(`catalogItems/${itemData.doc_id}`);
    if (itemData.industry !== "Google Garage") {
      firebaseDocument.update({type:myData.type})
      firebaseDocument.update({desc_short:myData.desc_short})
      firebaseDocument.update({industry:myData.industry})
      firebaseDocument.update({cases:myData.cases})
    }
    else {
      firebaseDocument.update({desc_full_below:myData.desc_full_below})
      firebaseDocument.update({project_link:myData.project_link})
      firebaseDocument.update({project_link2:myData.project_link2})
    }
    firebaseDocument.update({title:myData.title})
    firebaseDocument.update({priorityWorkloads:myData.priorityWorkloads})
    firebaseDocument.update({priorityPillars:myData.priorityPillars})
    firebaseDocument.update({contact:myData.contact})
    firebaseDocument.update({contact_name:myData.contact_name})
    firebaseDocument.update({desc_full:myData.desc_full})
    firebaseDocument.update({icon:myData.icon})
    firebaseDocument.update({keyTechnology:myData.keyTechnology})
    firebaseDocument.update({values:myData.values})
    
    getAllProducts();
    reset();

    setSuccessSubmit("Product successfully modified!");
  };

  try {
    const isGoogleGarage = itemData.industry === "Google Garage";
    return (
      props.admin && props.admin.includes(props.user.email) ?
      <div className="admin-form-wrapper col-md-8 col-xl-5">
        <h2>Edit product</h2>
        {
            <form id="admin-form" onSubmit={handleSubmit(onSubmit)}>
              
              {/* 1 - Title */}
              <div className="input-wrap">
                <label htmlFor="txt_title">
                  Title&nbsp;
                  <span className="text-muted">(Maximum 30 characters)</span>
                </label>
                <input ref={register({required: true, maxLength: 30})} type="text" name="title" id="txt_title" defaultValue={itemData.title} />
                <div className="errors">
                  {errors.title && 'Title is invalid. Titles are required and limited to 30 characters.'}
                </div>
              </div>

              {/* 2 - Type */}
              {!isGoogleGarage  && (
                <div className="input-wrap">
                  <label htmlFor="slct_category">Type</label>
                  <select ref={register({required: true})}  id="slct_category" name="type">
                    <option value={itemData.type}>{itemData.type}</option>
                    {
                      categoryOptions.map((item,i) =>
                        <option key={i} value={item}>{item}</option>
                      )
                    }
                  </select>
                  <div className="errors">
                    {errors.type && 'Type is required.'}
                  </div>
                </div>
              )}

              {/* X - Consumption */}
              {/* <div className="input-wrap">
                <label htmlFor="sldr_consumption">Consumption</label>
                <input ref={register} defaultValue="1" type="range" id="sldr_consumption" name="consumption"
                  onChange={handleSliderChange} min="1" max ="10" step="1" />
                <label>{consumptionValue}</label>
              </div> */}

              {/* 3 - References */}
              {!isGoogleGarage  && (
              <div className="input-wrap">
                <label htmlFor="txt_cases">
                  References&nbsp;
                  <span className="text-muted">(Return/enter for new point)</span>
                </label>
                <textarea ref={register({required: true})} name="cases" id="txt_cases" rows="5" defaultValue={itemData.cases.join("\n")}/>
                <div className="errors">
                  {errors.cases && 'A case is required'}
                </div>
              </div>
              )}

              {/* 4 - Priority Workloads */}
              <div className="input-wrap">
                <span className="text-muted">
                  Please expand the section below to select Priority Workloads (optional)
                </span>
                <button className={"toggle-pw col-12 "+(togglePW ? 'active' : null)} onClick={showHidePW}>
                  Priority Workloads
                  {
                    togglePW ? <img alt="arrow" src="../../Images/tab-chevron-up.svg"></img>
                    : <img alt="arrow" src="../../Images/tab-chevron-down.svg"></img>
                  }
                </button>
                <div className={"pw-wrapper "+(togglePW ? 'show' : null)}>
                  {props.pillars.map((pillar,i) => {
                    return (
                      <div key={i}
                        className={"pillar-wrapper "+(pillar.startsWith('Data Management') ? 'xp' : null)} >
                        <div className="pillar-header">
                          {pillar}
                        </div>
                        {
                          props.pworkloads.filter(obj =>
                            obj.pillar === pillar
                          )
                          .sort((a,b) => {
                            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
                          })
                          .map((item, index) => 
                            <div key={index} className="button-wrap">
                              <label>
                                <input type="checkbox"
                                  name={`PW.${pillar}.${item.name}`}
                                  ref={register}
                                  defaultChecked={itemData.priorityWorkloads.includes(item.name)}/>
                                <span></span>
                                {item.name}
                              </label>
                            </div>
                          )
                        }
                      </div>
                    )
                  })}
                </div>
              </div>

              {/* 5 - Contact Name */}
              <div className="input-wrap">
                <label htmlFor="txt_contact_name">Contact Name</label>
                <input ref={register({required: true, maxLength: 50, message:"Contact Name invalid."})}
                  type="text" name="contact_name" id="txt_contact_name" defaultValue={itemData.contact_name}/>
                  <div className="errors">
                    {errors.contact_name && 'Contact Name is invalid. It cannot be empty and is limited to 50 characters.'}
                  </div>
              </div>
              
              {/* 6 - Contact Email */}
              <div className="input-wrap">
                <label htmlFor="txt_contact">Contact Email</label>
                <input ref={register({required: true, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}})}
                  type="text" name="contact" id="txt_contact" defaultValue={itemData.contact}/>
                  <div className="errors">
                    {errors.contact && 'Email address is invalid. A valid email address is required.'}
                  </div>
              </div>
              
              {/* 7 - Short Description*/}
              {!isGoogleGarage  && (
                <div className="input-wrap">
                  <label htmlFor="txt_shortdesc">
                    Short Description&nbsp;
                    <span className="text-muted">(Maximum 80 characters)</span>
                  </label>
                  <input ref={register({required: true, maxLength: 80, message:"Short description invalid."})}
                    type="text" name="desc_short" id="txt_shortdesc" defaultValue={itemData.desc_short}/>
                  <div className="errors">
                    {errors.desc_short && 'Short description is invalid. A short description is required and limited to 80 characters.'}
                  </div>
                </div>
              )}

              {/* 8 - Full Description */}
              <div className="input-wrap">
                <label htmlFor="txt_fulldesc">Full Description</label>
                <textarea ref={register({required: true})} name="desc_full" id="txt_fulldesc" rows="5"  defaultValue={itemData.desc_full}/>
                <div className="errors">
                  {errors.desc_full && 'Full description is required.'}
                </div>
              </div>
              
              {/* 9 - Project Form Link*/}
              {isGoogleGarage  && (
              <div className="input-wrap">
                <label htmlFor="txt_project_link">Project Specifications Form Link</label>
                <input ref={register({required: true, message:"Project Specifications link invalid."})}
                  type="text" name="project_link" id="txt_project_link" defaultValue={itemData.project_link}/>
                  <div className="errors">
                    {errors.contact_name && 'Project link is invalid.'}
                  </div>
              </div>
              )}

              {/* 10 - Project Form Link 2*/}
              {isGoogleGarage  && (
              <div className="input-wrap">
                <label htmlFor="txt_project_link2">User Access Form Link</label>
                <input ref={register({required: true, message:"User Access form link invalid."})}
                  type="text" name="project_link2" id="txt_project_link" defaultValue={itemData.project_link2}/>
                  <div className="errors">
                    {errors.contact_name && 'User Access Form is invalid. It cannot be empty.'}
                  </div>
              </div>
              )}

              {/* 11 - Full Description 2*/}
              {isGoogleGarage  && (
              <div className="input-wrap">
                <label htmlFor="txt_fulldescbelow">Full Description Below Links</label>
                <textarea ref={register({required: true})} name="desc_full_below" id="txt_fulldescbelow" rows="5"  defaultValue={itemData.desc_full_below}/>
                <div className="errors">
                  {errors.desc_full_below && 'Full description is required.'}
                </div>
              </div>
              )}

              {/* 12 - Icon */}
              <div className="input-wrap">
                <label htmlFor="slct_icon">Icon Choice</label>
                <div className="info-wrap">
                  <input type="button" onClick={()=> setShowIconSprite(!showIconSprite)} value="&#9432;" className="info"></input>
                  { showIconSprite ?
                    <div className="img-wrap">
                      <i>click on the &#9432; to hide this</i>
                      <img src="../../Images/icons/icon-list.png" alt="icon-list"/>
                    </div> : null
                  }
                </div>
                <select ref={register({required: true})} id="slct_icon" name="icon">
                  <option value={itemData.icon}>{itemData.icon}</option>
                  {
                    iconOptions.map((item,i) =>
                      <option key={i} value={item}>{item}</option>
                    )
                  }
                </select>
                <div className="errors">
                  {errors.icon && 'Icon is required.'}
                </div>
              </div>
                
              {/* 13 - Industry */}
              {!isGoogleGarage  && (
              <div className="input-wrap">
                <label htmlFor="slct_industry">Industry</label>
                <select ref={register({required: true})}  id="slct_industry" name="industry">
                  <option value={itemData.industry}>{itemData.industry}</option>
                  {
                    industryOptions.map((item,i) =>
                      <option key={i} value={item}>
                        { item === 'Foundational' ? `N/A (${item})` : item }
                      </option>
                    )
                  }
                </select>
                <div className="errors">
                  {errors.industry && 'Industry is required.'}
                </div>
              </div>
              )}

              {/* 14 - Key Technologies */}
              <div className="input-wrap">
                <label htmlFor="txt_technology">
                  Key Technology&nbsp;
                  <span className="text-muted">(Maximum 50 characters)</span>
                </label>
                <input ref={register({required: true, maxLength: 50})}  type="text" name="keyTechnology" id="txt_technology" defaultValue={itemData.keyTechnology}/>
                <div className="errors">
                  {errors.keyTechnology && 'Key technology is invalid. Technology is required and limited to 50 characters.'}
                </div>
              </div>

              {/* 15 - Business Value Statements */}
              <div className="input-wrap">
                <label htmlFor="txt_values">
                  Business Value Statements&nbsp;
                  <span className="text-muted">(Return/enter for new point)</span>
                </label>
                <textarea ref={register({required: true})} name="values" id="txt_values" rows="5" defaultValue={itemData.values.join("\n")}/>
                <div className="errors">
                  {errors.values && 'A value is required'}
                </div>
              </div>

              <button className="btn-submit"><span>Submit</span></button>
              { successSubmit ?
                <p className="success">{successSubmit}</p>
                : null
              }
              
            </form>
        }
      </div> : <Redirect to="/404"></Redirect>)
  } catch(e) {
    console.log(e)
    return null;
  }
  
}


export default AdminEdit;
