import React from "react";
import { useParams } from "react-router-dom";
import CatalogItem from "./CatalogItem";
import CatalogItemGoogleGarage from "./CatalogItemGoogleGarage";

function CatalogItemRedirect(props) {
    let { doc_id } = useParams();
    const item = props.data.find(d => d.doc_id===doc_id)
    const industry = item && item.industry

    return industry === 'Google Garage' ? (
        <CatalogItemGoogleGarage
            admin={props.admin}
            user={props.user}
            data={props.data} />
    ) : (
        <CatalogItem
            admin={props.admin}
            user={props.user}
            data={props.data} /> 
    );
}

export default CatalogItemRedirect;