import React, { useState, useCallback } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Home from "./Pages/Home/Home";
import AdminAdd from "./Pages/Admin/Add";
import AdminEdit from "./Pages/Admin/Edit";
import CatalogItemRedirect from "./Pages/CatalogItem/CatalogItemRedirect";
import Category from "./Pages/Category/Category";
import { firebaseApp } from './utils/firebase';
import firebase from 'firebase/app';
import "./App.css";
import Missing from "./Pages/Missing/Missing";
import Search from "./Pages/Search/Search";
import GoogleGarageOnboard from "./Pages/GoogleGarage/Onboarding"
import ClientDataForm from "./Pages/MasterCloud/ClientDataForm"
import googleAuthentication from './utils/firebase';
import Stores from './context-stores';

function App() {

  // Gather data from database and possibly other sources to fill page with info/things
  const [userInfo, setUserInfo] = useState({}); // What is this?
  const [adminList, setAdminList] = useState([]);
  let   [catalogData, setCatalogData] = useState([]);
  const [priorityWorkloads, setPriorityWorkoads] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [pwPillars, setPWPillars] = useState([]);
  const [canAccess, setCanAccess] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [canDelAccess, setCanDelAccess] = useState(undefined);
  const session = Object.keys(sessionStorage).filter(item => item.indexOf("firebase:authUser") >= 0)[0]
  const analytics = firebase.analytics();

  const validateUser = useCallback((userInfo, industryListMod, isDataFetched) => {
    const delAccess = userInfo.email.indexOf('@deloitte') >= 0
    setCanAccess(
      userInfo.email.indexOf('@google') >= 0 ||
      userInfo.email === 'contactaliomar@gmail.com' ||
      userInfo.email === 'arshi.dhammi@gmail.com' ||
      delAccess ||
      userInfo.email === 'gcpofferings@gmail.com'
    );
    setCanDelAccess(
      delAccess
    );
    if (delAccess === false){
      removingGoogleGarage(industryListMod);
    }
  }, [])

  React.useEffect(() => {
    const authInfo = window.sessionStorage.getItem(session);
    setUserInfo(JSON.parse(authInfo));

    //--

    const db = firebaseApp.firestore();

    const fetchData = async (canDelAccess, fetched) => {
      if (fetched){
        return;
      }
      const adminRoles = await db.collection("userRoles")
        .where('role', '==', db.collection('roles').doc('2'))
        .get();

      const admins = adminRoles.docs.map(record => record.data().email);
      setAdminList(admins);

      //--

      const catalogData = await db.collection('catalogItems').get();
      const dataMapping = catalogData.docs.map(record => {
        let data = record.data();
        data.doc_id = record.id;
        return data;
      })

      setCatalogData(dataMapping)
      let ndIndustryList = [];
      for (let i = 0; i < dataMapping.length; i++) {
        ndIndustryList.push(dataMapping[i].industry);
      }
      let industryListUnique = [...new Set(ndIndustryList)];
      sortIndustries(industryListUnique);

      setIndustryList(industryListUnique);

      if (canDelAccess === false){
        removingGoogleGarage(industryListUnique);
      }
      setIsDataFetched(true)

    };
    fetchData(canDelAccess, isDataFetched);

    // ---priority workloads

    const fetchPriorityWorkloads = async () => {
      const collectionPW = await db.collection("priorityWorkloads").get();
      const dataPW = collectionPW.docs.map(obj => obj.data());
      setPriorityWorkoads(dataPW);

      let tempPillarList = [];
      for (let i = 0; i < dataPW.length; i++) {
        tempPillarList.push(dataPW[i].pillar);
      }
      let pillarList = [...new Set(tempPillarList)];
      setPWPillars(pillarList.sort());
    }
    fetchPriorityWorkloads();

    // validate user on page load - decide to show login screen or not
    if (authInfo) {
      validateUser(JSON.parse(authInfo), industryList);
    }
  }, [session, canDelAccess, industryList, isDataFetched, validateUser]);

  const sortIndustries = industryArray => {
    industryArray.sort(
      (a, b) => {
      if (a === "Foundational") {
        return 1;
      } else if (b === "Foundational") {
        return -1;
      } else if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  const onLoginClick = async (industryList) => {
    try {
      const userRes = await googleAuthentication();
      setUserInfo(userRes);
      validateUser(userRes, industryList);
    } catch (e) {
      // Error handle
    }
  }

  const logout = () => {
    setCanAccess(false)
    setCanDelAccess(undefined)
    setIsDataFetched(false)
    setIndustryList([])
  }

  const removingGoogleGarage = (industries) => {
    const index = industries.indexOf('Google Garage');
    if (index > -1) {
      industries.splice(index, 1);
      setIndustryList(industries)
    }
  }
  //////////

  const notLoggedIn = (
    <div className="app-login col-lg-4 col-md-8 m-auto">
      <div className="app-login-inner">
        <h3>GCP Offerings</h3>
        { userInfo && !canAccess
          ? <span>&#9888;&nbsp;Access Denied, please try again</span>
          : <span>Please sign-in to continue</span>
        }
        <br/>
        <button onClick={() =>onLoginClick(industryList)}>Sign In</button>
      </div>
    </div>
  )
  
  return (
    <Router>
      <ScrollToTop>
        { userInfo && canAccess ?
        <Stores>
          <div className="App">           
            <Header
              user={userInfo}
              setUser={setUserInfo}
              admin={adminList}
              session={session}
              industries={industryList}
              data={catalogData}
              resetState={logout}
            />
            <section className="main-body">
              <Switch>
                <Route exact path="/item/:doc_id">
                 <CatalogItemRedirect
                    admin={adminList}
                    user={userInfo}
                    data={catalogData} />
                </Route>
                {
                  industryList.map((item, index) => {
                    if (!canDelAccess && item === 'Google Garage'){
                      return null;
                    }
                    return (
                      <Route key={index} exact
                        path={"/industry/" + (item).toLowerCase().replace(/,/g, '').split(' ').join('-')}>
                        <Category data={catalogData} analytics={analytics} industry={item} />
                      </Route>);
                  }
                  )
                }
                <Route path="/admin/add">
                  <AdminAdd
                    setData={setCatalogData}
                    sortIndus = {sortIndustries}
                    user={userInfo}
                    pillars = {pwPillars}
                    pworkloads = {priorityWorkloads}
                    admin={adminList} />
                </Route>
                <Route path="/admin/edit/:doc_id">
                  <AdminEdit
                    data={catalogData}
                    setData={setCatalogData}
                    sortIndus = {sortIndustries}
                    user={userInfo}
                    pillars = {pwPillars}
                    pworkloads = {priorityWorkloads}
                    admin={adminList} />
                </Route>

                <Route path="/googlegarage/onboarding">
                  <GoogleGarageOnboard
                    analytics={analytics}
                    data={catalogData}
                    user={userInfo}

                    admin={adminList} />
                </Route>

                <Route exact path="/mastercloud/clientdataform">
                  <ClientDataForm/>
                </Route>

                <Route exact path="/search">
                  <Search analytics={analytics} data={catalogData} />
                </Route>


                <Route exact path="/">
                  <Home
                    analytics={analytics}
                    data={catalogData}
                    // user={userInfo}
                    industries={industryList} />
                </Route>

                <Route path="*">
                  <Missing data={catalogData} />
                </Route>
              </Switch>
            </section>
            <Footer />
          </div>
          </Stores>
          :
          // If not logged in or don't have access to the page (i.e. @deloitte.xx email ) then show this:
          notLoggedIn
        }
      </ScrollToTop>
    </Router>
  );
}

export default App;
