import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';

// PROD CONFIG
var firebaseConfig = {
  apiKey: "AIzaSyBsOTTbzHwPSsXmiUqV-pW9UXOckWxpifQ",
  authDomain: "partner-catalog-1.firebaseapp.com",
  databaseURL: "https://partner-catalog-1.firebaseio.com",
  projectId: "partner-catalog-1",
  storageBucket: "partner-catalog-1.appspot.com",
  messagingSenderId: "962717596386",
  appId: "1:962717596386:web:a62d39286a9b12d1a87cbb",
  measurementId: "G-2ZLTM7RNSV"
};

// SANDBOX CONFIG
// var firebaseConfig = {
//   apiKey: "AIzaSyBTKDcCowgVbB-SK0XPGI2nO8ER9y-l9gY",
//   authDomain: "partner-catalog-sandbox.firebaseapp.com",
//   databaseURL: "https://partner-catalog-sandbox.firebaseio.com",
//   projectId: "partner-catalog-sandbox",
//   storageBucket: "partner-catalog-sandbox.appspot.com",
//   messagingSenderId: "829398033716",
//   appId: "1:829398033716:web:33904ee41eac8bc4ecb161",
//   measurementId: "G-BFKN3JP9BG"
// };

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firebaseStorage = firebase.storage();

// Initialize Firebase Auth Provider
const provider = new firebase.auth.GoogleAuthProvider();

const googleAuthenticate = () => {
return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .then(function() {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    return firebase.auth().signInWithPopup(provider)
    .then(function(result) {
        // // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        const { email, displayName, photoURL } = user;
        //console.log(user) ---
        return { email, displayName, photoURL };
        // ...
    }).catch(function(error) {
        // // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // // ...
    });
  })
  .catch(function(error) {
    // Handle Errors here.
    //var errorCode = error.code;
    //var errorMessage = error.message;
  });
}

export default googleAuthenticate;
